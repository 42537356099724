import {
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";

import { moon } from "ionicons/icons";
import myUtil from "../helpers/cha-utils";

const Sidebar: React.FC = () => {
  // Add or remove the "dark" class based on if the media query matches

  const [darkMode, setDarkMode] = useState<boolean>(
    localStorage.getItem(myUtil.prefix + "dark-mode-status") === "true"
  );

  // console.log(darkMode);

  return (
    <IonMenu contentId="main-content">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Milligrams to Ounces</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList>
          <IonItem lines="full">
            <IonIcon slot="start" icon={moon}></IonIcon>
            <IonLabel>Toggle Dark Mode</IonLabel>
            <IonToggle
              id="themeToggle"
              slot="end"
              checked={darkMode}
              onIonChange={(e) => {
                myUtil.toggleDarkTheme(e.detail.checked);
                setDarkMode(e.detail.checked);
              }}
            ></IonToggle>
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonItem>
          <IonLabel>
            Developed By {` `}
            <a
              href="https://calculatorhub.app"
              target="_blank"
              rel="noreferrer"
            >
              CalculatorHub.app
            </a>
          </IonLabel>
        </IonItem>
      </IonFooter>
    </IonMenu>
  );
};

export default Sidebar;
